'use client';

import { FunctionComponent, useMemo } from 'react';
import { SnapScrollerList } from '../../../../../components/SnapScroller';
import { List } from '@shared/types';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { CollectionLink } from './CollectionLink';

export type CollectionsProps = {
    lists: List[];
    educatorSlug: PublicEducator['slug'];
};

export const Collections: FunctionComponent<CollectionsProps> = ({ lists, educatorSlug }) => {
    const publishedLists = useMemo(() => lists.filter((list) => list.published), [lists]);

    if (!publishedLists.length) {
        return null;
    }

    return (
        <>
            <h2>Collections</h2>
            <SnapScrollerList>
                {publishedLists.map((list) => {
                    return (
                        <CollectionLink
                            key={list._id}
                            collection={list}
                            educatorSlug={educatorSlug}
                        />
                    );
                })}
            </SnapScrollerList>
        </>
    );
};
