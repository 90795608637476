'use client';

import Link from 'next/link';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { FunctionComponent, MouseEventHandler, useCallback, useId } from 'react';
import BlueprintIcon from '../../../../components/BlueprintIcon';
import styles from './styles.module.scss';
import getWorkingLink from '../../../../services/getWorkingLink';
import { useIsMarketplace } from '../../../../contexts/AppContext/useIsMarketplace';
import { useSocialLinkRedirectAnalytics } from '../../../../hooks/Analytics/useSocialLinkRedirectAnalytics';

export type KeyDetailsProps = FunctionComponent<{
    educator: PublicEducator;
}>;

const KeyDetails: KeyDetailsProps = ({ educator }) => {
    const id = useId();

    return (
        <section
            className={styles.key_details_section}
            aria-labelledby={`${id}-key-details-header`}
        >
            <h2 id={`${id}-key-details-header`}>Key Details</h2>
            <ul className="cleaned_list_starter" aria-label="key-details-list">
                <WebsiteListItem educator={educator} />
                <PhoneNumberListItem educator={educator} />
                <SuggestEditItem educator={educator} />
                <OwnThisBusinessListItem educator={educator} />
            </ul>
        </section>
    );
};

type WebSiteListItemProps = FunctionComponent<{
    educator: PublicEducator;
}>;

const WebsiteListItem: WebSiteListItemProps = ({ educator }) => {
    const { recordRedirect } = useSocialLinkRedirectAnalytics({ educatorId: educator._id });
    const url = educator.links?.web;

    const onClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        async (event) => {
            if (!url) {
                return;
            }

            try {
                await recordRedirect('web', new URL(url));
            } catch (err) {
                console.error(`Invalid Social URL (${url})`);
            }
        },
        [url]
    );

    if (!url) {
        return null;
    }

    return (
        <li className={styles.key_details_item} aria-label="Educator website">
            <BlueprintIcon icon={'Globe'} className={styles.key_details_secondary_icon} />
            <a
                href={getWorkingLink(url)}
                target="_blank"
                rel="noopener noreferrer nofollow"
                onClick={onClick}
            >
                {url}
            </a>
        </li>
    );
};

export type PhoneNumberListItemProps = FunctionComponent<{
    educator: PublicEducator;
}>;

const PhoneNumberListItem: PhoneNumberListItemProps = ({ educator }) => {
    const { recordRedirect } = useSocialLinkRedirectAnalytics({ educatorId: educator._id });
    const tel = `tel:${educator.phone_number}`;

    const onClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        async (event) => {
            try {
                await recordRedirect('tel', new URL(tel));
            } catch (err) {
                console.error(`Invalid tel URL (${tel})`);
            }
        },
        [tel]
    );

    if (!educator.phone_number) {
        return null;
    }

    return (
        <li className={styles.key_details_item} aria-label="Educator phone number">
            <BlueprintIcon icon={'Phone'} className={styles.key_details_secondary_icon} />
            <a href={tel} target="_blank" rel="noopener noreferrer nofollow" onClick={onClick}>
                {educator.phone_number}
            </a>
        </li>
    );
};

export type KeyDetailProps = FunctionComponent<{ educator: PublicEducator }>;

const SuggestEditItem: KeyDetailProps = ({ educator }) => {
    const isMarketplace = useIsMarketplace();
    if (isMarketplace === false) {
        return null;
    }
    return (
        <li className={styles.key_details_item} aria-label="Suggest an edit">
            <BlueprintIcon icon={'Edit'} className={styles.key_details_secondary_icon} />
            <Link
                href={{
                    pathname: '/suggest-an-edit',
                    query: { slug: educator.slug, name: educator.name },
                }}
                rel="nofollow"
            >
                Suggest an edit
            </Link>
        </li>
    );
};

const OwnThisBusinessListItem: KeyDetailProps = ({ educator }) => {
    const isMarketplace = useIsMarketplace();
    if (isMarketplace === false) {
        return null;
    }
    if (educator.claimed === true) {
        return null;
    }
    return (
        <li className={styles.key_details_item} aria-label="Own this business?">
            <BlueprintIcon icon={'Learning'} className={styles.key_details_secondary_icon} />
            <Link href={{ pathname: `/${educator.slug}/claim-profile` }} rel="nofollow">
                Own this business?
            </Link>
        </li>
    );
};

export default KeyDetails;
