'use client';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { FunctionComponent, useCallback, useId, useState } from 'react';
import BlueprintIcon from '../../../../components/BlueprintIcon';
import ReactHTML from '../../../../components/ReactHTML';
import styles from './styles.module.scss';

const AboutUsSection: FunctionComponent<{ educator: PublicEducator }> = ({ educator }) => {
    const id = useId();

    const [showFullBio, setShowFullBio] = useState<boolean>(false);

    const handleButtonClick = useCallback(() => {
        setShowFullBio(!showFullBio);
    }, [showFullBio]);

    if (!educator.bio) {
        return null;
    }

    return (
        <section className={styles.bio_section} aria-labelledby={`${id}-about-header`}>
            <h2 id={`${id}-about-header`}>About {educator.name}</h2>
            <div
                className={
                    showFullBio || educator.bio.length <= 520
                        ? styles.educator_bio
                        : styles.educator_hidden_bio
                }
            >
                <p>
                    <ReactHTML html={educator.bio} />
                </p>
            </div>
            {educator.bio && educator.bio.length > 520 ? (
                <button className={styles.read_more_button} onClick={handleButtonClick}>
                    {showFullBio ? 'Read less' : 'Read more'}{' '}
                    <BlueprintIcon
                        icon={showFullBio ? 'ArrowUp' : 'ArrowDown'}
                        className={styles.read_more_icon}
                        height={12}
                        width={12}
                    />
                </button>
            ) : null}
        </section>
    );
};

export default AboutUsSection;
