'use client';

import { FunctionComponent, MouseEventHandler, useCallback } from 'react';
import {
    FacebookIcon,
    InstagramIcon,
    TwitterXIcon,
    PinterestIcon,
    LinkedinIcon,
    YoutubeIcon,
    LinkLightIcon,
} from '../Icons';
import classNames from 'classnames';
import { RecordRedirect } from '../../hooks/Analytics/useAnalytics';

type SocialIconLinkProps = {
    preset: 'facebook' | 'instagram' | 'twitter' | 'pinterest' | 'linkedin' | 'youtube';
    href: string;
    recordRedirect: RecordRedirect;
};

const getIconComponent: FunctionComponent = (iconType) => {
    switch (iconType) {
        case 'facebook':
            return <FacebookIcon className={classNames('profiles_icon', 'facebook_icon')} />;
        case 'instagram':
            return <InstagramIcon className={classNames('profiles_icon', 'instagram_icon')} />;
        case 'twitter':
            return <TwitterXIcon className={classNames('profiles_icon', 'twitter_x_icon')} />;
        case 'pinterest':
            return <PinterestIcon className={classNames('profiles_icon', 'pinterest_icon')} />;
        case 'linkedin':
            return <LinkedinIcon className={classNames('profiles_icon', 'linkedin_icon')} />;
        case 'youtube':
            return <YoutubeIcon className={classNames('profiles_icon', 'youtube_icon')} />;
        default:
            return <LinkLightIcon className={classNames('profiles_icon', 'web_link__icon')} />;
    }
};

const SocialIconLink: FunctionComponent<SocialIconLinkProps> = ({
    preset,
    href,
    recordRedirect,
}) => {
    const onClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        async (event) => {
            try {
                await recordRedirect(preset, new URL(href));
            } catch (err) {
                console.error('Invalid Social URL');
            }
        },
        [preset, href, recordRedirect]
    );

    const iconComponent = getIconComponent(preset);
    return (
        <li aria-label={`${preset} profile`}>
            <a
                aria-label={`${preset} page`}
                href={href}
                onClick={onClick}
                target="_blank"
                rel="noopener noreferrer"
            >
                {iconComponent}
            </a>
        </li>
    );
};

export default SocialIconLink;
