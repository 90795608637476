import { Category, MarketplaceTag } from '@shared/types';

export const getDedupedCategoriesAndTags = (
    categories: Array<Category>,
    marketplaceTags: Array<MarketplaceTag>
): Array<Category | MarketplaceTag> => {
    return marketplaceTags.reduce(
        (acc: Array<Category | MarketplaceTag>, current: MarketplaceTag) => {
            return acc.find((item) => item.name === current.name) ? acc : [...acc, current];
        },
        [...categories]
    );
};
