'use client';

import { useCallback } from 'react';
import { Educator } from '@shared/types';
import { usePageName } from '../usePageName';
import { RecordRedirect, useAnalytics } from '../useAnalytics';

export type UseSocialLinkRedirectAnalyticsOptions = {
    educatorId: Educator['_id'];
};

export type UseSocialLinkRedirectAnalyticsResult = {
    recordRedirect: RecordRedirect;
};

export const useSocialLinkRedirectAnalytics = ({
    educatorId,
}: UseSocialLinkRedirectAnalyticsOptions): UseSocialLinkRedirectAnalyticsResult => {
    const pageName = usePageName();
    const analytics = useAnalytics();

    const recordRedirect = useCallback<RecordRedirect>(
        async (linkType, href) => {
            if (typeof window === 'undefined') {
                return;
            }

            const url = new URL(window.location.href);

            await analytics.record.socialLinkRedirect(href, linkType, pageName, url, educatorId);
        },
        [educatorId, pageName, analytics]
    );

    return {
        recordRedirect,
    };
};
