import { Category, MarketplaceTag } from '@shared/types';

export const sortCategoriesAndTagsByName = (
    categoriesAndTagsArray: Array<Category | MarketplaceTag>
) => {
    return categoriesAndTagsArray.sort((a, b) => {
        const itemA = a.name;
        const itemB = b.name;
        if (itemA < itemB) {
            return -1;
        }
        if (itemA > itemB) {
            return 1;
        }
        return 0;
    });
};
