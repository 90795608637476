'use client';
import Link from 'next/link';
import { FunctionComponent, useMemo } from 'react';
import { List } from '@shared/types';
import { ListCard } from '../../../../../../components/ListCard';
import styles from './styles.module.scss';
import { useEducatorProfileLinks } from '../../../../../../hooks/useEducatorProfileLinks';

export type CollectionLinkProps = {
    collection: List;
    educatorSlug: string;
};

export const CollectionLink: FunctionComponent<CollectionLinkProps> = ({
    collection,
    educatorSlug,
}) => {
    const { coursesLink } = useEducatorProfileLinks(educatorSlug);
    const href = useMemo(() => {
        return `${coursesLink}?list=${encodeURIComponent(collection._id)}`;
    }, [collection, coursesLink]);
    return (
        <Link href={href} className={styles.link}>
            <ListCard list={collection} />
        </Link>
    );
};
