'use client';

import { FunctionComponent, useCallback, useId } from 'react';
import styles from './styles.module.scss';
import SocialIconLink from '../../../../components/SocialIconLink';
import getWorkingLink from '../../../../services/getWorkingLink';
import { useSocialLinkRedirectAnalytics } from '../../../../hooks/Analytics/useSocialLinkRedirectAnalytics';
import { PublicEducator } from '@cademy-package/sdk-component-types';

export type SocialProfilesProps = {
    educator: PublicEducator;
};

const SocialProfiles: FunctionComponent<SocialProfilesProps> = ({ educator }) => {
    const checkForSocials = useCallback((links: PublicEducator['links']) => {
        if (!links) {
            return false;
        }
        const socialLinkKeys = [
            'facebook',
            'instagram',
            'twitter',
            'pinterest',
            'linkedin',
            'youtube',
        ] as const;
        return socialLinkKeys.some((socialLinkKey) => {
            return Boolean(links[socialLinkKey]);
        });
    }, []);

    const { recordRedirect } = useSocialLinkRedirectAnalytics({ educatorId: educator._id });

    const id = useId();
    if (!educator.links || !checkForSocials(educator.links)) return null;
    const setLinks = educator.links;
    return (
        <section
            className={styles.profiles_section}
            aria-labelledby={`${id}-social-profiles-header`}
        >
            <h2 id={`${id}-social-profiles-header`}>Profiles</h2>
            <ul
                className={`${styles.profiles_breakdown} cleaned_list_starter`}
                aria-label="social-profiles-list"
            >
                {setLinks.facebook ? (
                    <SocialIconLink
                        href={getWorkingLink(setLinks.facebook)}
                        preset="facebook"
                        recordRedirect={recordRedirect}
                    />
                ) : null}
                {setLinks.instagram ? (
                    <SocialIconLink
                        href={getWorkingLink(setLinks.instagram)}
                        preset="instagram"
                        recordRedirect={recordRedirect}
                    />
                ) : null}
                {setLinks.twitter ? (
                    <SocialIconLink
                        href={getWorkingLink(setLinks.twitter)}
                        preset="twitter"
                        recordRedirect={recordRedirect}
                    />
                ) : null}
                {setLinks.pinterest ? (
                    <SocialIconLink
                        href={getWorkingLink(setLinks.pinterest)}
                        preset="pinterest"
                        recordRedirect={recordRedirect}
                    />
                ) : null}
                {setLinks.linkedin ? (
                    <SocialIconLink
                        href={getWorkingLink(setLinks.linkedin)}
                        preset="linkedin"
                        recordRedirect={recordRedirect}
                    />
                ) : null}
                {setLinks.youtube ? (
                    <SocialIconLink
                        href={getWorkingLink(setLinks.youtube)}
                        preset="youtube"
                        recordRedirect={recordRedirect}
                    />
                ) : null}
            </ul>
        </section>
    );
};

export default SocialProfiles;
