'use client';

import { FunctionComponent, useId } from 'react';
import { WorkingHours, WorkingHourRange } from '@shared/types';
import styles from './styles.module.scss';
import { ClockRegularIcon } from '../../../../components/Icons';

export type OpeningHoursProps = {
    workingHours?: WorkingHours | null;
};

const OpeningHours: FunctionComponent<OpeningHoursProps> = ({ workingHours }) => {
    const id = useId();
    const days: (keyof WorkingHours)[] = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ];

    const formatHours = (hours: WorkingHourRange[] | null): React.ReactNode => {
        if (!hours) return 'Closed';
        if (hours.length === 0) return 'Open 24 hours';
        return hours.map((range, index) => (
            <span key={index}>
                {index > 0 && <br />}
                {`${formatTime(range.from)} → ${formatTime(range.until)}`}
            </span>
        ));
    };

    const formatTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    if (!workingHours) return null;

    return (
        <section
            className={styles.openingHoursSection}
            aria-labelledby={`${id}-opening-hours-header`}
        >
            <h2 id={`${id}-opening-hours-header`}>Opening Hours</h2>
            <ul className="cleaned_list_starter" aria-label="opening-hours-list">
                {days.map((day) => (
                    <OpeningHoursListItem
                        key={day}
                        day={day}
                        hours={formatHours(workingHours[day])}
                    />
                ))}
            </ul>
        </section>
    );
};

type OpeningHoursListItemProps = {
    day: string;
    hours: React.ReactNode;
};

const OpeningHoursListItem: FunctionComponent<OpeningHoursListItemProps> = ({ day, hours }) => {
    return (
        <li className={styles.openingHoursItem} aria-label={`${day} hours`}>
            <ClockRegularIcon className={styles.openingHoursIcon} />
            <span className={styles.openingHoursDay}>
                {day.charAt(0).toUpperCase() + day.slice(1)}:
            </span>
            <span className={styles.openingHoursHours}>{hours}</span>
        </li>
    );
};

export default OpeningHours;
