'use client';

import { Category, MarketplaceTag, SystemTag, UserGeneratedTag } from '@shared/types';
import { FunctionComponent, useMemo, useId, useContext, useCallback } from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';
import { getDedupedCategoriesAndTags } from '../../../../services/getDedupedCategoriesAndTags';
import { sortCategoriesAndTagsByName } from '../../../../services/sortCategoriesAndTagsByName';
import { AppContext } from '../../../../contexts/AppContext';
import { useIsMarketplace } from '../../../../contexts/AppContext/useIsMarketplace';

type TeachesProps = {
    categories: Array<Category>;
    marketplace_tags: Array<MarketplaceTag>;
};

const isUserGeneratedTag = (item: Category | MarketplaceTag): item is UserGeneratedTag => {
    return 'educator_ref' in item;
};

const Comp: FunctionComponent<TeachesProps> = ({ categories, marketplace_tags }) => {
    const id = useId();
    const appContext = useContext(AppContext);

    const getItemSearchLink = useCallback(
        (item: Category | SystemTag): string => {
            switch (appContext.context) {
                case 'marketplace':
                    return `/courses/${item.slug}`;
                case 'minisite':
                case 'embed':
                    return `/?search=${item.slug}`;
            }
        },
        [appContext]
    );

    const dedupedCategoryAndTagsList = useMemo(() => {
        return getDedupedCategoriesAndTags(categories || [], marketplace_tags || []);
    }, [categories, marketplace_tags]);

    const sortedCategoryAndTagsList = useMemo(() => {
        if (dedupedCategoryAndTagsList.length > 2) {
            return sortCategoriesAndTagsByName(dedupedCategoryAndTagsList);
        }
        return dedupedCategoryAndTagsList;
    }, [dedupedCategoryAndTagsList]);

    const combinedCategoryAndTagsList = useMemo(() => {
        return (
            <ul className={styles.pill_list} aria-label="marketplace tag list">
                {sortedCategoryAndTagsList.map(
                    (teachesItem: Category | MarketplaceTag, index: number) => {
                        if (isUserGeneratedTag(teachesItem)) {
                            return (
                                <li
                                    key={`${teachesItem.name}-category-${index}`}
                                    id={`${teachesItem.name}-category-${index}`}
                                    className={styles.pill_list_item}
                                >
                                    {teachesItem.name}
                                </li>
                            );
                        }
                        return (
                            <li
                                key={`${teachesItem.name}-category-${index}`}
                                id={`${teachesItem.name}-category-${index}`}
                                className={styles.pill_list_item_link}
                            >
                                <Link href={getItemSearchLink(teachesItem)}>
                                    {teachesItem.name}
                                </Link>
                            </li>
                        );
                    }
                )}
            </ul>
        );
    }, [sortedCategoryAndTagsList, getItemSearchLink]);

    if (dedupedCategoryAndTagsList.length === 0) {
        return null;
    }

    return (
        <section className={styles.profiles_section} aria-labelledby={`${id}-teaches-header`}>
            <h2 id={`${id}-teaches-header`}>Teaches</h2>
            {combinedCategoryAndTagsList}
        </section>
    );
};

export const Teaches: FunctionComponent<TeachesProps> = (props) => {
    const isMarketplace = useIsMarketplace();

    if (isMarketplace === false) {
        return null;
    }

    return <Comp {...props} />;
};

export default Teaches;
