'use client';

import { PublicEducator } from '@cademy-package/sdk-component-types';
import React, { FunctionComponent } from 'react';
import { CourseCarousel } from '../../../../../components/CourseCarousel';
import { useEducatorProfileLinks } from '../../../../../hooks/useEducatorProfileLinks';
import { useShowVATLabel } from '../../../../../hooks/useShowVATLabel';

type CoursesSectionProps = {
    educator: PublicEducator;
    hasMarketplacePublishedCourses: boolean;
};

export const CoursesSection: FunctionComponent<CoursesSectionProps> = ({
    educator,
    hasMarketplacePublishedCourses,
}) => {
    const { coursesLink } = useEducatorProfileLinks(educator.slug);
    const showVATLabel: boolean = useShowVATLabel(educator.vat_settings);

    if (!hasMarketplacePublishedCourses || !educator.claimed) {
        return null;
    }

    return (
        <>
            <CourseCarousel
                heading="Courses"
                filters={{ educator_ref: [educator._id] }}
                endCardText={`View all listings by ${educator.name}`}
                endCardLink={coursesLink}
                name={`More listings from ${educator.name}`}
                showVATLabel={showVATLabel}
            />
        </>
    );
};
